import * as React from 'react';
import './DragHandleIcon.scss'

export type DragHandlePosition = 'top-right' | 'left' | 'left-center'
export type DragHandleSize = 'small' | 'large'

export interface DragHandleIconProps {
  listeners: any;
  attributes: any;
  position: DragHandlePosition,
  size: DragHandleSize,
  visible: boolean;
}

export default function DragHandleIcon({listeners, attributes, position, size, visible}: DragHandleIconProps) {
  return <svg className={`drag-icon drag-handle ${position} ${size} ${visible ? 'visible' : ''}`} {...listeners} {...attributes}  xmlns="http://www.w3.org/2000/svg" x="0" y="0" enableBackground="new 0 0 384 384" version="1.1" viewBox="0 0 384 384" xmlSpace="preserve"><circle cx="192" cy="42.667" r="42.667"></circle> <circle cx="192" cy="192" r="42.667"></circle> <circle cx="192" cy="341.333" r="42.667"></circle></svg>
}
