import {atom} from "recoil";
import {AppConfigDto, FullRevisionDto, LocationDto, MemberDto} from "../gen/client";
import Storage from "../util/Storage";

export const currentLocation = atom({
  key: 'currentLocation',
  default: {} as LocationDto
});

export const savingState = atom({
  key: 'savingState',
  default: false
});

export const locationIdState = atom({
  key: 'locationIdState',
  default: Storage.getBusinessId()
});

export const memberState = atom({
  key: 'memberState',
  default: {} as MemberDto
});

export const appConfigState = atom({
  key: 'appConfigState',
  default: {} as AppConfigDto
});

export const isOwnerState = atom({
  key: 'isOwnerState',
  default: true
});

export const revisionState = atom({
  key: 'revisionState',
  default: {} as FullRevisionDto
});

export const isRestoringState = atom({
  key: 'isRestoringState',
  default: false
});
