import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {Button} from 'antd';
import './MenuItemSortable.scss';
import {DND_TRANSITION} from '../../../util/constants';
import {DragHandle} from '../../misc/DragHandle';
import {MenuNode} from '../../../domain/Menu';

interface MenuListEntrySectionSortableProps {
  item: MenuNode;
  className?: string;
  dragging: boolean;
  showDragHandle: boolean;
  onClick?: () => void;
}

export function MenuListEntrySectionSortable({item, className, dragging, showDragHandle, onClick}: MenuListEntrySectionSortableProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: item.id, transition: DND_TRANSITION
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <li ref={setNodeRef} style={style} className={className} key={item.id}>
      {showDragHandle && <DragHandle attributes={attributes} listeners={listeners} position={'left'} size={'small'} />}

      <Button className={'btn-link'} type={'link'} onClick={onClick}>{item.name}</Button>
    </li>
  );
}