import * as React from "react";
import {Button, Input} from "antd";
import {MenuNode} from "../../../domain/Menu";
import {useEffect, useState} from "react";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons/lib";
import styles from "./MenuItemSizes.module.scss";
import Price from "../../Price";
import {BusinessDto} from "../../../gen/client";

interface MenuItemSizesProps {
  business: BusinessDto;
  item: MenuNode;
  onItemUpdate: (item: Partial<MenuNode>) => void;
}

export default function MenuItemSizes({item, business, onItemUpdate}: MenuItemSizesProps) {

  const [count, setCount] = useState(0);

  useEffect(() => {
    if (item && (item.sizes || item.prices)) {
      let sizes = item.sizes ? item.sizes.length : 0;
      let prices = item.prices ? item.prices.length : 0;

      setCount(Math.max(sizes, prices));
    }
  }, [item]);

  function onPriceChange(price: any, idx: number) {
    //price = !business.orderingEnabled ? price : (!price ? 0 : parseFloat(price));
    const updated = [...item.prices];
    updated[idx] = price;

    onItemUpdate({prices: updated});
  }

  function onSizeChange(size: string, idx: number) {
    const updated = [...item.sizes];
    updated[idx] = size || "";

    onItemUpdate({sizes: updated});
  }

  function addSize() {
    if (item.sizes && item.sizes.length) {
      item.prices = [...item.prices, null];
    }
    let updated = [...item.sizes, ""];

    if (updated.length === 1) {
      updated = [...updated, ""];

      item.prices = [...item.prices, null];
    }

    onItemUpdate({sizes: updated});
  }

  function removeSize(idx: number) {
    const updatedSizes = [...item.sizes];
    updatedSizes.splice(idx, 1);

    const updatedPrices = [...item.prices];
    updatedPrices.splice(idx, 1);

    onItemUpdate({
      sizes: updatedSizes,
      prices: !updatedPrices.length ? [...item.prices] : updatedPrices
    });
  }
  
  return (
    <div className={styles.sizes}>
      <ul>
        {(!count || count === 1) ? (
          <li key={1}>
            <span><Price business={business} currency={business?.config.currency} edit value={item.prices[0]} onChangeText={(p) => onPriceChange(p, 0)} /></span>
          </li>
        ) : (
          item.prices.map((price, idx) => (
            <li key={`${idx}`}>
              <Input className={styles.sizeInput} size="large" defaultValue={item.sizes[idx] || ""} placeholder={"Size"} onBlur={(ev) => onSizeChange(ev.target.value, idx)} />
              <Price business={business} currency={business?.config.currency} edit value={price} onChangeText={(p) => onPriceChange(p, idx)} />

              {count >= 1 && (
                <span className={styles.remove}>
                  <DeleteOutlined onClick={() => removeSize(idx)} />
                </span>
              )}
            </li>
          ))
        )}
      </ul>
      {count < 6 ? (
        <Button onClick={addSize} style={{marginRight: "auto"}}>
          <PlusOutlined />
          {"Size"}
        </Button>
      ) : null}
    </div>
  );
}
