import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {BusinessDto, LocationDto, MenuDto, OrderDto} from "../../gen/client";
import {getBusinessPageUrl, isNotFoundError, isPaymentRequiredError, openInNewTab} from "../../util/Utils";
import './BusinessOverview.scss'
import {Button, Skeleton} from "antd";
import {BusinessApi} from "../../api/BusinessApi";
import {AxiosError} from "axios";
import {handleServerError} from "../../util/ErrorHandler";
import {TODAY} from "../../domain/Order";
import MobilePreview from "./MobilePreview";
import BusinessToDos from "./BusinessToDos";
import {copyBusinessUrl, hasHoursConfigured, isBusinessOpen} from "../../services/BusinessService";
import BusinessTodayHours from "../restaurant/BusinessTodayHours";
import {getMenusPath, redirectToLogin, RoutesEnum} from "../../RoutesEnum";
import {NavLink} from "react-router-dom";
import {setPageTitle} from "../../util/seo";
import MemberApi from "../../api/MemberApi";
import PublishButton from "../PublishButton";
import { useRecoilValue } from 'recoil';
import {memberState} from "../../store/atoms";

interface BusinessOverviewProps {
  isLoading: boolean;
  business: BusinessDto;
  location: LocationDto;
  saving: boolean;
  publishing: boolean;
  menus: MenuDto[];
  isPublished: boolean;
  memberStatus: number;
  onBusinessUpdate: (path: BusinessDto) => void;
  onPublish: (published: boolean) => void;
  checkPublishingPossibility: () => void;
}

export default function BusinessOverview({business, location, saving, publishing, isLoading, menus, isPublished, memberStatus, onBusinessUpdate, onPublish, checkPublishingPossibility}: BusinessOverviewProps) {
  const [, setOrders] = useState([] as OrderDto[]);
  const [open, setOpen] = useState(false);
  const [menuCount, setMenuCount] = useState(null);
  const [viewCount, setViewCount] = useState(null);
  const [hoursConfigured, setHoursConfigured] = useState(false);
  const member = useRecoilValue(memberState);

  const fetchLightDashboardData = useCallback(() => {
    if (!member.id || !business.id) return;

    MemberApi.getLightDashboardData(member.id, business.id).then(resp => {
      setMenuCount(resp.data.menuCount);
      setViewCount(resp.data.viewCount);
    }).catch(err => {
      if (isPaymentRequiredError(err)) {
        redirectToLogin();
      } else {
        if (!isNotFoundError(err)) {
          handleServerError(err);
        }
      }
    });
  }, [member.id, business.id]);

  useEffect(fetchLightDashboardData, [fetchLightDashboardData]);

  const visibilityChangeListener = useCallback(() => {
    if (!document.hidden) {
      fetchLightDashboardData();
    }
  }, [fetchLightDashboardData]);

  useEffect(() => {
    document.addEventListener('visibilitychange', visibilityChangeListener, false);

    return () => {
      document.removeEventListener('visibilitychange', visibilityChangeListener, false);
    }
  }, [fetchLightDashboardData, visibilityChangeListener]);

  useEffect(() => {
    setPageTitle(business?.config?.name)
  }, [business]);

  useEffect(() => {
    if (!business || !business.id) return;
    if (business.orderingEnabled) {
      BusinessApi.getOrders(business.id, TODAY).then(resp => {
        setOrders(resp.data);
      }).catch((err: AxiosError) => {
        handleServerError(err);
      });
    }
    setOpen(isBusinessOpen(business));
    setHoursConfigured(hasHoursConfigured(business));
  }, [business]);

  function onViewMenusClick() {
    openInNewTab(getBusinessPageUrl(business));
  }

  function getMenuId() {
    const candidate = menus.length > 0 ? menus.filter(it => !it.removed) : null;
    return candidate && candidate.length ? candidate[0].id : 'new';
  }

  return isLoading ? <Skeleton className={'biz-load'} active/> : (<>
    {isPublished && <div className={'design-site-link'}>
      <h6>Site link: <a href={getBusinessPageUrl(business)} target="_blank"
                        rel="noreferrer">{getBusinessPageUrl(business)}</a></h6>
      <PublishButton business={business} saving={saving} publishing={publishing} isPublished={isPublished} memberStatus={memberStatus} checkPublishingPossibility={checkPublishingPossibility} />
    </div>}
    <div className={'business-overview'}>
      <h1>{business?.config?.name}</h1>

      <section>
        <div className={'data'}>
          <MobilePreview business={business} menus={menus} />
          <section className={'content'}>
            {hoursConfigured && <header className={`availability ${open ? '' : 'cl'}`}>
              <h2>{open ? 'Open now' : 'Closed'}</h2>
              <BusinessTodayHours business={business} open={open} label={false}/>
            </header>}

            <div className={'buttons'}>
              <div>
                <Button onClick={onViewMenusClick} disabled={!isPublished} className={"ant-btn btn-lg btn-sec-1"}>Preview</Button>
                <NavLink to={`${RoutesEnum.DashboardSettingsHours}`} className={'ant-btn btn-lg btn-sec-1'}>Edit Business Hours</NavLink>
              </div>
              <div>
                <NavLink to={getMenusPath(business.id, getMenuId())} className={'ant-btn btn-lg btn-sec-1'}>Edit Menus</NavLink>
                <Button disabled={!isPublished} onClick={() => copyBusinessUrl(business)} className={"ant-btn btn-lg btn-sec-1"}>Copy Link</Button>
              </div>
            </div>

            <ul className={'stats'}>
              <li>
                <h2>{menuCount !== null ? menuCount : menus.length}</h2>
                <h3>Menus</h3>
              </li>
              <li>
                <h2>{viewCount !== null ? viewCount : business.viewCount}</h2>
                <h3>Total Views</h3>
              </li>
              <li>
                <h2>{isPublished ? 'Published' : 'Unpublished'}</h2>
                <h3>Status</h3>
              </li>
            </ul>
            <BusinessToDos business={business} menus={menus} published={isPublished} memberStatus={memberStatus} onBusinessUpdate={onBusinessUpdate} checkPublishingPossibility={checkPublishingPossibility}/>
          </section>
        </div>

        {/*<aside>
          <section className={'enable-oo'}>
            <h3>Online ordering Coming Soon!</h3>
            <Button type={"dashed"} onClick={goToFeaturePage}>Learn More</Button>
            <div className={'crc'} />
          </section>
        </aside>*/}
      </section>

    </div></>
  );
};
