import {ImageDtoSourceEnum} from "../gen/client";

export enum MenuItemType {
  ITEM, SECTION
}

export class MenuItemData {
  id: string;
  name: string;
  description: string;
  preview: string;
  price: string;
  type: MenuItemType;
  modifiers: MenuItemModifier[];
  extras: string;

  constructor(id: string, type: MenuItemType = MenuItemType.ITEM) {
    this.id = id;
    this.type = type;
  }
}

export enum MenuNodeType {
  SECTION, ITEM
}

export class MenuNode {
  id: string;
  name: string;
  description: string;
  cookTime: string;
  preview: string;
  previewSource: ImageDtoSourceEnum;
  type: MenuNodeType;
  nodes: MenuNode[];
  prices: string[];
  sizes: string[];
  available: boolean;
  modifiers: MenuItemModifier[];
  extras: string;

  constructor(id: string, type: MenuNodeType = MenuNodeType.SECTION, prices: string[] = [], available: boolean = true, modifiers: MenuItemModifier[] = [], extras: string = '') {
    this.id = id;
    this.type = type;
    this.nodes = [];
    this.prices = prices;
    this.sizes = [];
    this.available = available;
    this.modifiers = modifiers;
    this.extras = extras;
  }
}

export class MenuItemModifier {
  id: string;
  name: string;
  required: number;
  options: MenuItemModifierOption[];

  constructor(id: string, name: string = '', required: number = 1, options: MenuItemModifierOption[] = []) {
    this.id = id;
    this.name = name;
    this.required = required;
    this.options = options;
  }
}

export class MenuItemModifierOption {
  id: string;
  name: string;
  price?: number;
  description?: string;

  constructor(id: string, name: string = '', price: number = 0, description: string = '') {
    this.id = id;
    this.name = name;
    this.price = price;
    this.description = description;
  }
}
