import * as React from 'react';
import {ReactNode} from "react";
import styles from './NotificationCard.module.scss';
import {Button} from "antd";

interface NotificationCardProps {
  children: ReactNode,
  onClick?: () => void
}

export default function NotificationCard({children, onClick}: NotificationCardProps) {
  return <Button type={'link'} className={styles.card} onClick={onClick}>{children}</Button>;
}
