import * as React from 'react';
import {useCallback, useEffect, useState, useRef} from 'react';
import {message, Skeleton} from "antd";
import {MenuDto, BusinessDto} from "../../../gen/client";
import {getBusinessPageUrl, uuid} from "../../../util/Utils";
import {useHistory} from "react-router";
import MenuApi from "../../../api/MenuApi";
import {handleServerError} from "../../../util/ErrorHandler";
import MenuList from "./MenuList";
import DashboardMenu from "./DashboardMenu";
import styles from './DashboardMenus.module.scss';
import {getMenusPath, isMenusPath, RoutesEnum} from "../../../RoutesEnum";
import {MenuNode} from '../../../domain/Menu';
import {menuSubject} from '../../../store/Subjects';
import _ from 'lodash';
import {env} from '../../../env/env';
import RouteLeaveGuard from '../../misc/RouteLeaveGuard';
import MakeFirstMenuActiveModal from './MakeFirstMenuActiveModal';
import EmptyItemModal from './EmptyItemModal';
import MobilePreviewWrapper from "../MobilePreviewWrapper";
import {EmptyMenu} from '../../../util/constants';
import {setPageTitle} from "../../../util/seo";
import PublishButton from "../../PublishButton";

interface MenuProps {
  business: BusinessDto;
  menus: MenuDto[];
  saving: boolean;
  publishing: boolean;
  isPublished: boolean;
  memberStatus: number;
  checkPublishingPossibility: () => void;
  onMenuAdded: (menu: MenuDto) => void;
  onMenuRemoved: (menuId: string) => void;
  onMenuEdited: (menu: MenuDto) => void;
  onCurrencyChange: (currency: string) => void;
  showShareModal: () => void;
  onMenuSort: (menus: MenuDto[]) => void;
  onSectionSort: (menu: MenuDto, sections: MenuNode[]) => void;
  onSave: (finished: boolean) => void;
}

export default function DashboardMenus({business, menus, saving, publishing, isPublished, memberStatus, checkPublishingPossibility, onCurrencyChange, onMenuAdded, onMenuRemoved, onMenuEdited, onMenuSort, onSectionSort, onSave}: MenuProps) {
  const history = useHistory();

  const [id, setId] = useState('');
  const [menu, setMenu] = useState(EmptyMenu);
  const [isLoading, setLoading] = useState(false);
  const [emptyItemCount] = useState(0);
  const [isCreateSuccessModal, setCreateSuccessModal] = useState(false);
  const [isEmptyItemModal, setEmptyItemModal] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null as MenuNode);

  const saveChanges = useRef(_.debounce((newMenu: MenuDto, businessId: string) => {
    onSave(false);
    !newMenu.id ? create(newMenu, businessId) : update(newMenu);
  }, env.autoSaveDebounce));

  function update(menu: MenuDto) {
    MenuApi.updateMenu(menu)
      .then(() => onSave(true))
      .catch(handleServerError);
  }

  function create(menu: MenuDto, businessId: string) {
    MenuApi.saveMenu({
      ...menu,
      businessId,
      active: true
    }).then(resp => {
      onMenuAdded(resp.data);
      onSave(true);
      history.push(getMenusPath(business.id, resp.data.id));
    }).catch(handleServerError);
  }

  useEffect(() => {
    const unregister = history.listen((location) => {
      setCreateSuccessModal(!isMenusPath(location.pathname) && !!menus && !!menus.length && !menus.find(it => it.active));
    });

    return () => {
      unregister();
    }
  }, [menus, history]);

  useEffect(() => {
    const menuObs = menuSubject.subscribe((newMenu) => {
      setMenu(newMenu);
      onMenuEdited(newMenu);

      if (menu !== EmptyMenu) {
        saveChanges.current(newMenu, business.id);
      }
    });
    return () => {
      menuObs.unsubscribe();
    };
  }, [business.id, menu, onMenuEdited]);

  function onMenuUpdate(updated: Partial<MenuDto>, scrollToBottom: boolean) {
    menuSubject.next({...menu, ...updated});

    setScrollToBottom(scrollToBottom);
  }

  const fetchMenu = useCallback((path?: string) => {
    if (!path) path = getPathId(history.location.pathname);

    if (path === 'new') {
      menuSubject.next({nodes: JSON.stringify([new MenuNode(uuid())])});
    } else {
      setLoading(true);
      MenuApi.getMenu(path).then((resp) => {
        const menu = resp.data;
        if (menu.removed) {
          message.error(`${'Menu not found'}!`, 3);
          return;
        }
        menuSubject.next(menu);
        setLoading(false);
      }).catch(err => {
        setLoading(false);
        handleServerError(err);
      });
    }
  }, [history]);

  useEffect(() => {
    setPageTitle('Menus');
    fetchMenu();
  }, [fetchMenu]);

  useEffect(() => {
    return history.listen((location) => {
      if (!isMenusPath(location.pathname)) return;
      const path = getPathId(location.pathname);
      if (path === id) return;
      setId(path);
      fetchMenu(path);
    });
  }, [id, history, fetchMenu]);

  function getPathId(pathname: string) {
    const bits = pathname.split(RoutesEnum.MenusPath + '/');
    return bits[bits.length - 1];
  }

  function onSectionClick(section: MenuNode, sectionMenu: MenuDto) {
    if (menu.id !== sectionMenu.id) {
      history.push(getMenusPath(business.id, sectionMenu.id));
    }
    setSelectedSection(section);
  }

  function onEmptyModalOk() {
    setEmptyItemModal(false);
  }

  return (<div>
      {isPublished && <div className={styles.siteLink}>
        <h6>Site link: <a href={getBusinessPageUrl(business)} target="_blank" rel="noreferrer">{getBusinessPageUrl(business)}</a></h6>
        <PublishButton business={business} saving={saving} publishing={publishing} isPublished={isPublished} memberStatus={memberStatus} checkPublishingPossibility={checkPublishingPossibility} />
      </div>}

    <div className={'step stacked'}>
      <div className={styles.splitter}>
        <MenuList business={business} menus={menus} active={menu} onMenuSort={onMenuSort} onSectionSort={onSectionSort} onSectionClick={onSectionClick}/>

        {isLoading && <Skeleton className={styles.loading} active/>}
        {!isLoading && <DashboardMenu business={business} menu={menu} scrollToBottom={scrollToBottom} selectedSection={selectedSection} published={isPublished}
                                      onCurrencyChange={onCurrencyChange} onMenuRemoved={onMenuRemoved} onMenuUpdate={onMenuUpdate} menus={menus}/>}
      </div>

      <MobilePreviewWrapper menus={menus} business={business} isPublished={isPublished}/>
    </div>

    <RouteLeaveGuard enabled={isCreateSuccessModal} element={<MakeFirstMenuActiveModal isVisible={true} onCancel={() => setCreateSuccessModal(false)} onOk={() => onMenuUpdate({active: true}, false)}/>}/>

    <EmptyItemModal isVisible={isEmptyItemModal} count={emptyItemCount} onOk={onEmptyModalOk} onCancel={() => setEmptyItemModal(false)}/>
  </div>);
};
