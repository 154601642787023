import {closestCenter} from "@dnd-kit/core";
import {restrictToFirstScrollableAncestor, restrictToVerticalAxis} from "@dnd-kit/modifiers";
import {verticalListSortingStrategy} from "@dnd-kit/sortable";
import {ActionButtonType} from "../components/dashboard/website/designer/constants/ActionButtonType";
import {BusinessDto, MenuDto} from "../gen/client";
import {ColorPair} from "./types";
import {Moment} from "moment";

export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const REF = 'ref';
export const SESSION = 'ses';
export const PASSWORD = 'pas';

export const PNG_TYPE = 'image/png';
export const IMG_OCTET_TYPE = 'image/octet-stream';

export const AM = 'am';
export const PM = 'pm';

export interface Currency {
  key: string,
  label: string
}

export const CURRENCIES: Currency[] = [
  {key: '$', label: '$'},
  {key: '€', label: '€'},
  {key: '£', label: '£'},
  {key: 'LEI', label: 'LEI'}
];


export interface BusinessType {
  key: string,
  label: string
}

export const PAYMENT_CARD_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "17px",
      "::placeholder": {
        color: "#32325d"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

export const DEFAULT_REVIEW_BUTTON = {
  action: ActionButtonType.Reviews
};

export const DEFAULT_MENUS_BUTTON = {
  action: ActionButtonType.Menus
};

export const DND_MODIFIERS = [restrictToVerticalAxis, restrictToFirstScrollableAncestor];
export const DND_COLLISION_DETECTION = closestCenter;
export const DND_SORTABLE_STRATEGY = verticalListSortingStrategy;
export const DND_TRANSITION = {
  duration: 500,
  easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
};

export const HOUR_FORMAT_24 = 'HH:mm';
export const HOUR_FORMAT_AM_PM = 'h:mm';
export const HOUR_FORMAT_AM_PM_FULL = 'h:mm a';
export const HOUR_FORMAT_24_NEXT_OPEN = 'ddd HH:mm';
export const HOUR_FORMAT_24_NEXT_OPEN_SAME_DAY = 'HH:mm';
export const HOUR_FORMAT_AM_PM_NEXT_OPEN = 'ddd h:mm A';
export const HOUR_FORMAT_AM_PM_NEXT_OPEN_SAME_DAY = 'h:mm A';

export const EmptyBusiness = {config: {}} as BusinessDto;
export const EmptyMenu = {} as MenuDto;

export const Colors = [
  new ColorPair('#000000', '#efefef', '#000000', '#000000'),
  new ColorPair('#033a54', '#e24941', '#FFFFFF', '#033a54'),
  new ColorPair('#beafc2', '#695e93', '#FFFFFF', '#30235e'),
  new ColorPair('#2a303c', '#b8cec8', '#2a303c', '#000000'),
  new ColorPair('#000000', '#717f69', '#eeeeee', '#000000'),
  new ColorPair('#421011', '#eed7b7', '#421011', '#421011'),
];

export const DEFAULT_TEXT_COLOR = '#000000';
export const DEFAULT_BG_COLOR = '#EFEFEF';

export function doNothing() {}

export const ALLOWED_IMAGE_TYPES = 'image/png,image/x-png,image/jpeg,/image/jpg';

export enum PublishFailReason {
  NO_MENUS, NO_BUSINESS_INFO, URL_TAKEN
}

export const BANNER_TEXT_MAX_LENGTH = 140;

export interface Schedule {
  start: Moment;
  end: Moment;
}

export const EMPTY_SCHEDULE = {
  start: null,
  end: null
} as Schedule

export const MAX_IMAGE_SIZE = 10;

export const MEMBER_STATUS = {
  ACTIVE: 1,
  HOLD: 2,
  CANCELLED: 3,
  DECLINED: 4,
  FREEMIUM: 5,
  PAST_DUE: 7
}

export const PLACEHOLDER_WEBSITE = 'https://www.your-website.com';
export const PLACEHOLDER_EMAIL = 'you@youremail.com';
export const PLACEHOLDER_TWITTER = '@yourhandle';
export const PLACEHOLDER_FACEBOOK = 'facebook.com/yourpage';
export const PLACEHOLDER_INSTAGRAM = '@yourhandle';
export const PLACEHOLDER_PHONE = '(555) 555-5555';

export enum ChooseImageTab {
  UPLOAD = "1", CHOOSE = "2"
}

export const isTestEnv = window.location.href.match('test.mhmfun.com');
export const isStageEnv = window.location.href.match('staging.mhmfun.com');
export const isProdEnv = window.location.href.match('musthavemenus.com');