import * as React from 'react';
import {BusinessDto} from "../../gen/client";
import './BusinessTodayHours.scss';
import {hasBusinessHours, parseHours} from "../../services/BusinessService";
import {formatBusinessHour} from '../../util/Utils';
import {BusinessHoursCalculator} from "../../util/BusinessHoursCalculator";

interface BusinessTodayHoursProps {
  business: BusinessDto;
  label?: boolean;
  open: boolean;
}

export default function BusinessTodayHours({business, label, open}: BusinessTodayHoursProps) {
  const hasHours = hasBusinessHours(business);

  let hours: React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>[] = [];
  if (hasHours) {
    const today = new BusinessHoursCalculator(parseHours(business.config.hours)).getTodayHours();
    if (today && today.open && today.hours && today.hours.length > 0) {
      today.hours.forEach((h, idx) => {
        const {start, end} = h;
        if (start === '00:00' && end === '24:00') {
          hours.push(<label>{'All day'}</label>);
        } else {
          hours.push(<label key={idx}>{formatBusinessHour(start, business.config.use24HourFormat)} - {formatBusinessHour(end, business.config.use24HourFormat)}</label>);
        }
      })
    }
  }

  return (
    <div className={`rth ${open ? '' : 'cl'}`}>
      {
        label ? (
          <label className={'av'}>
            {open ? 'open' : 'closed'}
            {hours}
          </label>
        ) : hours
      }
    </div>
  )
};
