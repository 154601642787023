import {AxiosPromise} from "axios";
import {AppConfigDto, AppResourceApi} from "../gen/client";
import {apiConfig, ops} from "./ApiConfig";

const api = new AppResourceApi(apiConfig);

const appApi = {
  getAppConfig(): AxiosPromise<AppConfigDto> {
    return api.getAppConfig(ops());
  }
};

export default appApi;
