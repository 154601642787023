import React, {useEffect, useState} from 'react';
import {arrayMove, SortableContext, useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {MenuDto} from '../../../gen/client';
import {Button, Collapse} from 'antd';
import './MenuItemSortable.scss';
import './MenuListEntrySortable.scss';
import {DND_COLLISION_DETECTION, DND_MODIFIERS, DND_SORTABLE_STRATEGY, DND_TRANSITION} from '../../../util/constants';
import {DragHandle} from '../../misc/DragHandle';
import {DndContext, DragEndEvent, DragOverlay, PointerSensor, useSensor, useSensors} from '@dnd-kit/core';
import {MenuListEntrySectionSortable} from './MenuListEntrySectionSortable';
import {DownOutlined} from '@ant-design/icons';
import {MenuNode} from '../../../domain/Menu';
import {createPortal} from 'react-dom';

interface MenuListEntrySortableProps {
  item: MenuDto;
  className: string;
  onMenuClick: (active: MenuDto) => void;
  onSectionClick: (active: MenuNode) => void;
  onSectionSort: (sections: MenuNode[]) => void;
  active: boolean;
  opened: boolean;
  showDragHandle: boolean;
}

const {Panel} = Collapse;

export function MenuListEntrySortable({item, className, active, opened, showDragHandle, onSectionClick, onMenuClick, onSectionSort}: MenuListEntrySortableProps) {
  const [childDragging, setChildDragging] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState(active || opened);
  const [sections, setSections] = useState([] as MenuNode[]);

  useEffect(() => {
    if (item && item.nodes) {
      setSections(JSON.parse(item.nodes || '[]'));
    }
  }, [item]);

  const DND_SENSORS = useSensors(useSensor(PointerSensor));

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: item.id, transition: DND_TRANSITION
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  function onDragEnd(event: DragEndEvent) {
    const {active, over} = event;

    if (active.id !== over.id) {
      const oldIndex = sections.findIndex(it => it.id === active.id);
      const newIndex = sections.findIndex(it => it.id === over.id);

      onSectionSort(arrayMove(sections, oldIndex, newIndex));
    }
    setChildDragging(false);
  }

  function onDragStart() {
    onMenuClick(item);
    setChildDragging(true);
  }

  function onArrawClick(e: React.MouseEvent<HTMLInputElement>) {
    e.stopPropagation();

    setCollapseOpen(!collapseOpen);
  }

  return (
    <li ref={setNodeRef} style={style} className={className} key={item.id}>
      {showDragHandle && <DragHandle attributes={attributes} listeners={listeners} position={'left'} size={'small'} />}

      <Button className={'btn-link'} type={'link'} onClick={() => onMenuClick(item)}>{item.name} {!!sections.length && <DownOutlined className={`expand-icon ${collapseOpen ? 'open' : 'closed'}`} onClick={onArrawClick} />}</Button>

      <Collapse activeKey={collapseOpen ? 'open' : 'closed'} expandIconPosition={'right'} ghost>
        <Panel header='' key='open' showArrow={false}>
          <div className={'section-container'}>
            <DndContext modifiers={DND_MODIFIERS} sensors={DND_SENSORS} collisionDetection={DND_COLLISION_DETECTION} onDragEnd={onDragEnd} onDragStart={onDragStart} autoScroll={false}>
              {createPortal(<DragOverlay wrapperElement="ul"> </DragOverlay>, document.body)}
              <SortableContext strategy={DND_SORTABLE_STRATEGY} items={sections.map(it => it.id)}>
                <ul>
                  {sections.map(it => <MenuListEntrySectionSortable key={it.id} showDragHandle={sections.length > 1} item={it} dragging={childDragging} onClick={() => onSectionClick(it)}/>)}
                </ul>
              </SortableContext>
            </DndContext>
          </div>
        </Panel>
      </Collapse>
    </li>
  );
}