import React, {Suspense, useCallback, useRef} from 'react';
import {useEffect} from 'react';
import './App.scss';
import {BrowserRouter, Switch, Route} from "react-router-dom";
import {Layout} from 'antd';
import AuthPage from "./components/AuthPage";
import MemberApi from "./api/MemberApi";
import AppApi from "./api/AppApi";
import Loader from "./components/AppLoader";
import Dashboard from "./components/dashboard/Dashboard";
import Storage from "./util/Storage";
import {isPublicPage, redirectToLogin, RoutesEnum} from './RoutesEnum';
import LightBusinessPage from "./components/order/LightBusinessPage";
import {env} from "./env/env";
import LightBusinessNotFound from './components/order/LightBusinessNotFound';
import useQueryString from "./hooks/useQueryString";
import _, {parseInt} from "lodash";
import {MemberDto, MemberStatusDto} from "./gen/client";
import {AxiosResponse} from "axios";
import {socketConnect} from "./services/SocketService";
import {useRecoilState, useSetRecoilState} from "recoil";
import {appConfigState, isOwnerState, locationIdState, memberState} from "./store/atoms";
import {getJSPath, loadJS} from "./util/ScriptLoader";
import { datadogRum } from '@datadog/browser-rum';
import { isStageEnv, isTestEnv } from './util/constants';

const {Content} = Layout;

export default function App() {
  const [locationIdQuery] = useQueryString<string>('businessId', '');
  const [locationId, setLocationId] = useRecoilState(locationIdState);
  const setMember = useSetRecoilState(memberState);
  const setConfig = useSetRecoilState(appConfigState);
  const setIsOwner = useSetRecoilState(isOwnerState);
  const [tkn] = useQueryString<string>('tkn', Storage.getToken());
  const throttledVisibilityChangeHandler = useRef(_.debounce(checkStillLoggedIn, 1000));

  useEffect(() => {
    loadJS(getJSPath(), () => {
      console.log('=> payment script loaded.');
    });
  }, []);

  useEffect(() => {
    if (!isPublicPage(window.location.href)) {
      document.addEventListener('visibilitychange', () => {
        if (!document.hidden) {
          throttledVisibilityChangeHandler.current();
        }
      }, false);

      window.addEventListener('storage', () => {
        if (!Storage.getToken()) {
          redirectToLogin();
        }
      });
    }
  }, []);

  useEffect(() => {
    if (locationIdQuery) {
      setLocationId(locationIdQuery)
    }
  }, [locationIdQuery, setLocationId])

  const logoutIfRequired = useCallback((resp: AxiosResponse<MemberStatusDto>) => {
    if (!resp.data.authenticated) {
      redirectToLogin();
    }
  }, []);

  useEffect(() => {
    if (!locationId) return;
    MemberApi.getStatus(tkn, parseInt(locationId)).then(resp => {
      logoutIfRequired(resp);
      const {member} = resp.data;

      socketConnect(locationId, member.name, member.id);

      Storage.setMemberId(member.id);
      setMember(member);
      setIsOwner(resp.data.owner);
      initDataDog(member!);
    });
    AppApi.getAppConfig().then(resp => {
      setConfig(resp.data);
    });
  }, [tkn, logoutIfRequired, locationId, setConfig, setMember, setIsOwner]);

  function checkStillLoggedIn() {
    MemberApi.getStatus(null, parseInt(locationId)).then(logoutIfRequired);
  }

  useEffect(() => {
    if (!!tkn && tkn !== 'null') {
      Storage.setToken(tkn);
    }
  }, [tkn]);

  useEffect(() => {
    if (!!locationId && locationId !== 'null') {
      Storage.setBusinessId(locationId);
    }
  }, [locationId]);

  function initDataDog(member: MemberDto) {
    datadogRum.init({
      applicationId: 'a9eb41c0-1c23-4de5-9302-c8a981bc5821',
      clientToken: 'pub8a2bc7e7c1fe41c60ada75d06bd3871d',
      site: 'datadoghq.com',
      service:'musthavemenus',
      env: isTestEnv ? 'test' : isStageEnv ? 'stage' : 'prod',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      silentMultipleInit: true,
    });
    datadogRum.setUser({
      id: member.id?.toString(),
      name: member.name!,
      email: member.email!,
    })
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader/>}>
        <Layout className={`app`}>
          <Content>
            <Switch>
              <Route path={`${RoutesEnum.Order}/:url/menus/:menu`}><LightBusinessPage/></Route>
              <Route path={`${RoutesEnum.Order}/:url`}><LightBusinessPage/></Route>
              <Route path={RoutesEnum.App}><Dashboard/></Route>
              {env.debug && <Route path={RoutesEnum.Root}><AuthPage/></Route>}
              <Route path={`${RoutesEnum.LightOrder}/:url`}><LightBusinessPage/></Route>
              <Route path="*"><LightBusinessNotFound found={false}/></Route>
            </Switch>
          </Content>
        </Layout>
      </Suspense>
    </BrowserRouter>
  );
}
