import * as React from 'react';
import {useState} from 'react';
import {MenuDto, BusinessDto} from "../../gen/client";
import {Card} from "antd";
import {useHistory} from "react-router-dom";
import NotificationCard from "../layout/NotificationCard";
import {copyBusinessUrl, hasBusinessHours} from "../../services/BusinessService";
import {RoutesEnum} from '../../RoutesEnum';
import './BusinessToDos.scss';
import {getLPDashboardLink, getLPMainAppLink} from "../../util/Utils";
import MemberApi from "../../api/MemberApi";
import {handleServerError} from "../../util/ErrorHandler";
import QRLimitModal from "../QRLimitModal";
import ToDoCheck from "../misc/icons/ToDoCheck";
import {useRecoilValue} from "recoil";
import {memberState} from "../../store/atoms";

interface BusinessToDosProps {
	business: BusinessDto;
	menus: MenuDto[];
	published: boolean;
	memberStatus: number;
	onBusinessUpdate: (path: BusinessDto) => void;
	checkPublishingPossibility: () => void;
}

function hasRestaurantToDos(business: BusinessDto, menus: MenuDto[], published: boolean) {
	return !hasBusinessHours(business) ||
		(menus.length === 0) ||
		(business.orderingEnabled && !business?.config?.onlineCardPayment && !business?.config?.deliveryCardPayment && !business?.config?.deliveryCashPayment) ||
		(!business?.config?.orderDelivery && !business?.config?.pickUpOrders) ||
		!business.config.color ||
		(menus.length > 0 && !published) ||
		(!business.config.facebook && !business.config.instagram && !business.config.twitter);
}

export default function BusinessToDos({business, published, menus, memberStatus, onBusinessUpdate, checkPublishingPossibility}: BusinessToDosProps) {
  	const history = useHistory();
		const member = useRecoilValue(memberState);
  	const [qrLimitModal, setQRLimitModal] = useState(false);

  	function goToNewMenu() {
		history.push(RoutesEnum.DashboardMenusNew);
  	}

  	function goToHours() {
		history.push(RoutesEnum.DashboardSettingsHours);
  	}

  	function goToPayment() {
		history.push(RoutesEnum.DashboardSettingsPayments);
  	}

  	function goToDelivery() {
		history.push(RoutesEnum.DashboardSettingsOrdering);
  	}

  	function goToDesign() {
		history.push(RoutesEnum.DashboardDesign);
  	}

  	function goToSettings() {
		history.push(RoutesEnum.DashboardSettings);
  	}

  	function onShare() {
		copyBusinessUrl(business);
	
		onBusinessUpdate({
			...business,
			config: {
				...business.config,
				toDoShare: true
			}
		});
  	}

  	function onDesign() {
		onBusinessUpdate({
	  		...business,
	  		config: {
				...business.config,
				toDoDesign: true
	  		}
		});
		goToDesign();
  	}

  	function goToLanding() {
		let newTab = window.open();
		MemberApi.getLandingPages(member.id).then(resp => {
	  		const landingPages = resp.data;

	  		if (landingPages.length > 1) {
				newTab.location.href = getLPMainAppLink();
	  		} else {
				let id = 'new';
				if (landingPages.length === 1) {
		  			id = landingPages[0].id;
				}
				newTab.location.href = getLPDashboardLink(business.id, id);
	  		}
		}).catch(handleServerError);
  	}

  	function goToQr() {
		var isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
			navigator.userAgent &&
			navigator.userAgent.indexOf('CriOS') === -1 &&
			navigator.userAgent.indexOf('FxiOS') === -1;

		if (isSafari) {
			//let newTab = window.open();
			// MemberApi.getQRCodes(memberId).then(resp => {
			// 	const codes = resp.data;
			// 	const path = isStage() ? RoutesEnum.MyStuffStaging : RoutesEnum.MyStuff;
			// 	if (codes.length >= 1 && memberStatus !== MEMBER_STATUS.ACTIVE) {
			// 		newTab.close();
			// 		setQRLimitModal(true);
			// 	} else {
			// 		newTab.location.href = path + '?qr-code-type=online-menu&qr-code-create=true#qr-codes';
			// 	}
			// }).catch(handleServerError);
		} else {
			// MemberApi.getQRCodes(memberId).then(resp => {
			// 	const codes = resp.data;
			// 	const path = isStage() ? RoutesEnum.MyStuffStaging : RoutesEnum.MyStuff;
			// 	if (codes.length >= 1 && memberStatus !== MEMBER_STATUS.ACTIVE) {
			// 		setQRLimitModal(true);
			// 	} else {
			// 		window.open(path + '?qr-code-type=online-menu&qr-code-create=true#qr-codes', '_blank');
			// 	}
			// }).catch(handleServerError);
		}
  	}

  	return hasRestaurantToDos(business, menus, published) ? (<Card className={'business-to-dos'}>
		<header>
		<h3>Next Steps</h3>
		</header>

		{!published && <>
		{menus.length === 0 && 
		<NotificationCard onClick={goToNewMenu}>
			<ToDoCheck/> Add menu content
		</NotificationCard>}
		
		{!business.config.toDoDesign && 
		<NotificationCard onClick={onDesign}>
			<ToDoCheck/> Customize design
		</NotificationCard>
		}
		
		{!hasBusinessHours(business) &&
		<NotificationCard onClick={goToHours}>
			<ToDoCheck/> Set business hours
		</NotificationCard>
		}

		{menus.length > 0 && !published &&
			<NotificationCard onClick={() => checkPublishingPossibility()}>
			<ToDoCheck/> Publish Online Menu
			</NotificationCard>
		}
		</>}

		{published && <>
		{!business.config.facebook && !business.config.instagram && !business.config.twitter && 
		<NotificationCard onClick={goToSettings}>
			<ToDoCheck/> Add contact links
		</NotificationCard>
		}

		{!business.config.toDoShare && <NotificationCard onClick={onShare}>
			<ToDoCheck/> Get a share link to improve views
		</NotificationCard>
		}

		{/* TODO: how do we mark this as completed? add flag to business cfg? */}
		<NotificationCard onClick={goToLanding}>
			<ToDoCheck/> Add to a link page
		</NotificationCard>

		{/* TODO: how do we mark this as completed? add flag to business cfg? */}
		<NotificationCard onClick={goToQr}>
			<ToDoCheck/> Share as a QR Code
		</NotificationCard>

		{business.orderingEnabled && !business?.config?.onlineCardPayment && !business?.config?.deliveryCardPayment && !business.config.deliveryCashPayment &&
		<NotificationCard onClick={goToPayment}>
			<ToDoCheck/> Configure payments
		</NotificationCard>}

		{!business?.config?.orderDelivery && !business?.config?.pickUpOrders &&
		<NotificationCard onClick={goToDelivery}>
			<ToDoCheck/> Configure deliveries
		</NotificationCard>}
		</>}

		<QRLimitModal visible={qrLimitModal} onCancel={() => setQRLimitModal(false)}/>
	</Card>) : null;
};
