import {env} from "../env/env";
import {
  HOUR_FORMAT_24,
  HOUR_FORMAT_24_NEXT_OPEN_SAME_DAY,
  HOUR_FORMAT_AM_PM_NEXT_OPEN_SAME_DAY,
  IMG_OCTET_TYPE,
  PNG_TYPE
} from "./constants";
import {BusinessDto, MenuDto} from "../gen/client";
import moment, {Moment} from "moment";
import {BusinessHoursDto} from "../domain/BusinessHours";
import {AxiosError, AxiosResponse} from "axios";
import Storage from "./Storage";
import {RoutesEnum} from "../RoutesEnum";

interface IdBased {
  id?: number | string;
}

export function getQRCodeUrl(qrCodeId: string): string {
  return `${env.qrPath}/qr/${qrCodeId}`;
}

export function getBusinessPageUrl(business: BusinessDto): string {
  if (window.location.href.indexOf('ohbz.com') > -1) {
    return `https://ohbz.com/oo/${business.customUrl || business.url}`;
  } else {
    return `${env.basePath}${env.contextPath}/${business.customUrl || business.url}`;
  }
}

export function getBusinessPageQRUrl(locationId: string): string {
  return `${env.basePath}/places/qr/${locationId}`;
}

export function isPreviewUrl() {
  return window.location.href.indexOf('preview=true') > -1;
}

export function getMenuPageUrl(business: BusinessDto, menu: MenuDto): string {
  return `${getBusinessPageUrl(business)}?mid=${menu.friendlyId}`
}

export function isMenuPageUrl(url: string): boolean {
  return url.indexOf('reviews') < 0;
}

export function patch(list: IdBased[], entry: IdBased) {
  const index = list.findIndex(it => it.id === entry.id);
  if (index > -1) {
    list.splice(index, 1, entry);
    return list;
  } else {
    return [...list];
  }
}

export function isNotEmptyObject(obj: any): boolean {
  return !isEmptyObject(obj);
}

export function isEmptyObject(obj: any): boolean {
  if (obj === null) return true
  return Object.keys(obj).length === 0;
}

export function isHexLight(color: string): boolean {
  const hex = color.replace('#', '');
  const c_r = parseInt(hex.substr(0, 2), 16);
  const c_g = parseInt(hex.substr(2, 2), 16);
  const c_b = parseInt(hex.substr(4, 2), 16);
  const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
  return brightness > 170;
}

export function uuid() {
  return Math.random().toString(36).substr(2, 10);
}

export function toLogin() {
  if (isStage()) {
    window.location.href = RoutesEnum.MyStuffStaging;
  } else if (isProd()) {
    window.location.href = RoutesEnum.MyStuffProd;
  } else {
    window.location.href = RoutesEnum.MyStuff;
  }
}

const formatOps = {month: 'long', day: '2-digit', hour: 'numeric', minute: 'numeric'} as Intl.DateTimeFormatOptions;
const formatter = new Intl.DateTimeFormat('en', formatOps);

export function format(date: Date): string {
  return formatter.format(date);
}

export function formatBusinessHour(date: string, use24HourFormat: boolean) {
  return moment(date, HOUR_FORMAT_24).format(use24HourFormat ? HOUR_FORMAT_24_NEXT_OPEN_SAME_DAY : HOUR_FORMAT_AM_PM_NEXT_OPEN_SAME_DAY);
}

export function isDayOpen(hours: BusinessHoursDto, day: string) {
  const key = day as keyof BusinessHoursDto;
  return hours[key] && hours[key].open;
}

export function dayHours(hours: BusinessHoursDto, day: string) {
  return hours[day as keyof BusinessHoursDto].hours
}


export function tick(callback: () => void) {
  setTimeout(callback, 0);
}

export function download(url: string, name: string) {
  let downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = name;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

export function downloadQr(id: string, name: string) {
  const canvas = document.getElementById(id) as HTMLCanvasElement;
  const url = canvas.toDataURL(PNG_TYPE).replace(PNG_TYPE, IMG_OCTET_TYPE);
  download(url, name);
}

function fallbackClipboardCopy(text: string) {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  document.execCommand('copy');
  document.body.removeChild(textArea);
}

export function clipboardCopy(text: string) {
  if (!navigator.clipboard) {
    fallbackClipboardCopy(text);
    return;
  }
  navigator.clipboard.writeText(text).then((() => {
  }));
}

export function getTimezoneOffset() {
  return new Date().getTimezoneOffset();
}

export function scrollToTop() {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
}

export function byClass(name: string): HTMLCollectionOf<Element> {
  return document.getElementsByClassName(name);
}

export function getPageLinkFromPathName(pathname: string) {
  return pathname.substring(pathname.lastIndexOf('/') + 1);
}

export function deepCopy(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}

export function openInNewTab(url: string) {
  window.open(url, '_blank').focus();
}

export function defaultSelectFilterOption(input: string, option: any) {
  return option.value.toLowerCase().indexOf(input.toLowerCase()) === 0
}

export function defaultSelectFilterSort(one: any, two: any) {
  return one.value.toLowerCase().localeCompare(two.value.toLowerCase());
}

export function timberImgPath(id: string, width: number = 300): string {
  return `${env.rendererPath}/design/thumbnail/${id}?width=${width}`;
}

export function scrollToElement(el: HTMLDivElement) {
  if (el) {
    tick(() => window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'}));
    tick(() => {
      if (el) {
        el.scrollIntoView({behavior: "smooth"});
      }
    });
  }
}

export function scrollToElementWithOffset(el: HTMLElement, offset: number = 0) {
  if (el) {
    const elementPosition = el.getBoundingClientRect().top + window.pageYOffset;
    const offsetPosition = elementPosition - offset;

    tick(() => {
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    });
  }
}

export function isInViewport(el: HTMLElement, offset: number = 0) {
  if (!el) return true;

  const rect = el.getBoundingClientRect();
  return (
    rect.top - offset >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function rotateImage(base64data: string, mimeString: string, right: boolean): Promise<string> {
  return new Promise((resolve, reject) => {
      try {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext("2d");
        const image = new Image();

        image.src = base64data;
        image.onload = () => {
          try {
            canvas.width = image.height;
            canvas.height = image.width;
            if (right) {
              ctx.rotate(90 * Math.PI / 180);
              ctx.translate(0, -canvas.width);
            } else {
              ctx.rotate(270 * Math.PI / 180);
              ctx.translate(-canvas.height, 0);
            }
            ctx.drawImage(image, 0, 0);

            resolve(canvas.toDataURL(mimeString || 'image/jpeg', 1));
          } catch (e) {
            reject(e);
          }
        };
      } catch (e) {
        reject(e);
      }
    }
  );
}

export function getBase64Response(resp: AxiosResponse) {
  let raw = Buffer.from(resp.data).toString('base64');
  return 'data:' + resp.headers['content-type'] + ';base64,' + raw;
}

export function fromBase64ToBlob(base64: string, mimeString: string): Blob {
  const byteString = atob(base64.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], {type: mimeString});
}

export function getMimeStringFromBase64(base64: string) {
  return base64.split(',')[0].split(':')[1].split(';')[0];
}

export function isPaymentRequiredError(err: AxiosError) {
  return (err.code && err.code === '402') || (err.response && err.response.status && err.response.status === 402)
}

export function isNotFoundError(err: AxiosError) {
  return (err.code && err.code === '404') || (err.response && err.response.status && err.response.status === 404)
}

export function isAuthError(err: AxiosError) {
  return (err.code && err.code === '401') || (err.response && err.response.status && err.response.status === 401)
}

export function timestamp(value: Moment): number {
  if (value === null) return null;
  return value.unix();
}

export function fromTimestamp(ts: number): Moment {
  return moment.unix(ts);
}

export function capitalize(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function isStage() {
  return window.location.href.indexOf('/oostage') > -1;
}

export function isProd() {
  return (window.location.href.indexOf('musthavemenus.com') > -1 || window.location.href.indexOf('ohbz.com') > -1) && !isStage();
}

export function getLPDashboardLink(businessId: string, id: string) {
  const tkn = Storage.getToken();
  if (isProd()) {
    return `https://ohbz.com/lp/app/${id}/links?businessId=${businessId}&tkn=${tkn}&link=om`;
  } else if (isStage()) {
    return `https://stage.mhme.nu/app/${id}/links?businessId=${businessId}&tkn=${tkn}&link=om`;
  } else {
    return `https://test.mhmfun.com/lpv2/app/${id}/links?businessId=${businessId}&tkn=${tkn}&link=om`;
  }
}

export function getLPMainAppLink() {
  if (isStage()) {
    return `https://staging.mhmfun.com/home/link-pages`;
  }
  return 'https://www.musthavemenus.com/home/link-pages';
}

export function getAccountNewLocationPage() {
  if (isStage()) {
    return 'https://staging.mhmfun.com/account-stage/locations/new';
  } else if (isProd()) {
    return 'https://www.musthavemenus.com/account/locations/new';
  }
  return 'https://test.mhmfun.com/account-test/locations/new';
}

export function hasValidLengthUrl(url: string) {
  return url.length <= 25
}

export function urlHasInvalidChars(url: string) {
  return !/^[a-zA-Z0-9-_.]+$/.test(url)
}

export function goToMainApp() {
  if (isStage()) {
    window.location.href = 'https://staging.mhmfun.com/home-stage';
  } else if (isProd()) {
    window.location.href = 'https:///www.musthavemenus.com/home';
  } else {
    window.location.href = 'https://test.mhmfun.com/home-test/';
  }
}
export function trailingZero(value: number) {
  return (`${value}`.length < 2) ? `0${value}` : value;
}
