import {ImageResourceApi} from "../gen/client";
import {AxiosPromise} from "axios";
import {apiConfig, ops} from "./ApiConfig";

const api = new ImageResourceApi(apiConfig);

const ImageApi = {
  getImage(url: string): AxiosPromise<Array<string>> {
    return api.getImage(url, {
      ...ops(),
      responseType: 'arraybuffer'
    });
  },
}

export default ImageApi;
