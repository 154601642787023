import * as React from 'react';
import './ImportItemModal.scss';
import {Button, Input} from "antd";
import {ChangeEvent, useCallback, useEffect, useRef} from "react";
import MenuApi from "../../../api/MenuApi";
import {handleServerError} from "../../../util/ErrorHandler";
import {useState} from "react";
import {BusinessDto, TimberMenuDto} from "../../../gen/client";
import {timberImgPath} from "../../../util/Utils";
import {SearchOutlined} from "@ant-design/icons/lib";
import {debounce} from 'lodash';
import Loader from "../../misc/Loader";

export interface ImportItemDesignsProps {
  active: TimberMenuDto;
  onSelect: (design: TimberMenuDto) => void;
  onClose: () => void;
  onNext: () => void;
  business: BusinessDto;
}

export default function ImportItemDesigns({active, onSelect, onClose, onNext, business}: ImportItemDesignsProps) {
  const [loading, setLoading] = useState(true);
  const [designs, setDesigns] = useState([] as TimberMenuDto[]);

  const initialized = useRef(false);
  const mounted = useRef(false);
  const key = useRef('');

  const fetchMenus = useCallback((search: string, page: number, businessId: number) => {
    if (page === 1) {
      setDesigns([]);
    }

    setLoading(true);
    key.current = `${search}-${page}`;

    MenuApi.getTimberMoMenus(businessId, search, page).then(resp => {
      if (!mounted.current) return;
      if (key.current !== `${search}-${page}`) return;

      setDesigns(designs => [...designs, ...resp.data.list]);

      if (resp.data.hasNext) {
        fetchMenus(search, page + 1, businessId)
      } else {
        setLoading(false);
      }
    }).catch(err => {
      setLoading(false);
      handleServerError(err);
    });
  }, []);

  const performSearch = debounce((key) => {
    fetchMenus(key, 1, parseInt(business.id));
  }, 1500);

  useEffect(() => {
    mounted.current = true;
    if (!initialized.current) {
      fetchMenus('', 1, parseInt(business.id));
      initialized.current = true;
    }
  }, [business.id, fetchMenus]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, []);

  function onSearchChange(ev: ChangeEvent<HTMLInputElement>) {
    performSearch(ev.target.value);
  }

  return (
    <>
      <header>
        <h6>Select a design to import items</h6>
        <Input prefix={<SearchOutlined/>} placeholder={'Search'} onChange={onSearchChange}/>
      </header>

      {designs.length === 0 && !loading && <p>No designs</p>}
      {designs.length > 0 && <div className={'scroller'}>
        <ul className={'designs'}>
          {designs.map(it => <li key={it.id} className={it.id === active?.id ? 'act' : ''} onClick={() => onSelect(it)}>
            <img src={timberImgPath(it.id)} alt={it.name}/>
            <h5>{it.name}</h5>
          </li>)}
        </ul>
      </div>}

      {loading && <Loader />}

      <footer>
        <Button className={"btn-med btn-cancel"} onClick={onClose}>Cancel</Button>
        <Button className={"btn-med"} type={'primary'} disabled={!active} onClick={onNext}>Import Items</Button>
      </footer>
    </>
  );
}
