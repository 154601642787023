import './ChooseImageModal.scss'
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import MemberApi from "../api/MemberApi";
import {handleServerError} from "../util/ErrorHandler";
import {Button, Modal, Tabs, Upload} from "antd";
import {ChooseImageTab} from "../util/constants";
import {MemberImageDto} from "../gen/client";
import Loader from "./misc/Loader";
import Masonry from 'react-masonry-component';
import {getImagePath} from "../services/ImageService";
import SelectableImage from './SelectableImage';
import UploadIcon from "./misc/icons/UploadIcon";
import {useRecoilState} from "recoil";
import {currentLocation} from "../store/atoms";

interface ChooseImageModalProps {
  memberId: number;
  onClose: () => void;
  onChoice: (img: MemberImageDto) => void;
  onUpload: (file: File) => void;
}

const {TabPane} = Tabs;
const {Dragger} = Upload;

export default function ChooseImageModal({memberId, onClose, onChoice, onUpload}: ChooseImageModalProps) {
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(ChooseImageTab.UPLOAD);
  const [memberImages, setMemberImages] = useState(null as MemberImageDto[]);
  const [selectedImage, setSelectedImage] = useState({} as MemberImageDto);
  const [location] = useRecoilState(currentLocation);

  const loadMemberImages = useCallback(() => {
    if (!memberId || !location.internalId) return;

    setLoading(true);
    MemberApi.getMemberImages(memberId, location.internalId).then(resp => {
      setMemberImages(resp.data.sort((a, b) => parseInt(b.createdDate || '0') - parseInt(a.createdDate || '0')));
      setLoading(false);
    }).catch(e => {
      setLoading(false);
      handleServerError(e);
    });
  }, [memberId, location]);

  useEffect(() => {
    if (selectedTab === ChooseImageTab.CHOOSE) {
      if (!memberImages && !loading) {
        loadMemberImages();
      }
    }
  }, [selectedTab, memberImages, loading, loadMemberImages]);

  function onTabChange(key: string) {
    setSelectedTab(key as ChooseImageTab)
  }

  function onAddImage() {
    onChoice(selectedImage);
  }

  return <Modal className={'choose-image-modal'} visible={true} onCancel={onClose} footer={null} width={570}>
    <div className={`container ${selectedTab === ChooseImageTab.UPLOAD ? 'upload' : 'choose'}`}>
      <Tabs defaultActiveKey={selectedTab} onChange={onTabChange} className={selectedTab === ChooseImageTab.CHOOSE ? 'with-border': ''}>
        <TabPane tab="Upload Image" key={ChooseImageTab.UPLOAD} className={'upload-tab'}>
          <Dragger name={'file'} multiple={false} beforeUpload={onUpload}>
            <p className="ant-upload-drag-icon">
              <span className={'anticon anticon-cloud-upload'}>
                <UploadIcon/>
              </span>
            </p>
            <p className="ant-upload-text">
              <span>Drag and drop files here or</span>
              <Button>Choose Images</Button>
            </p>
          </Dragger>
        </TabPane>
        <TabPane tab="My Images" key={ChooseImageTab.CHOOSE} className={'image-tab'}>
          {loading && <Loader/>}

          {!loading && memberImages &&
              <>
                {!memberImages.length && <span>No saved images yet!</span>}

                {!!memberImages.length &&
                    <Masonry className={'member-image-gallery'} options={{fitWidth: true}}>
                      {memberImages.map(img => <SelectableImage key={img.id} src={getImagePath(img.path, img.source === 'oo')}
                                                                selected={selectedImage.id === img.id} onClick={() => setSelectedImage(img)}/>)}
                    </Masonry>}
              </>}
        </TabPane>
      </Tabs>

      <footer className={'ant-modal-footer'}>
        <Button onClick={onClose}>Cancel</Button>
        {selectedTab === ChooseImageTab.CHOOSE &&
            <Button disabled={!selectedImage.id} type={`${selectedImage.id ? 'primary' : 'default'}`} onClick={onAddImage}>Add Image</Button>}
      </footer>
    </div>
  </Modal>;
}
