import * as React from 'react';
import {BusinessDto, MenuDto} from "../../../gen/client";
import {useHistory} from "react-router";
import {Button} from "antd";
import styles from "./MenuList.module.scss";
import {getMenusPath, RoutesEnum} from '../../../RoutesEnum';
import {arrayMove, SortableContext} from '@dnd-kit/sortable';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {MenuListEntrySortable} from './MenuListEntrySortable';
import {DND_COLLISION_DETECTION, DND_MODIFIERS, DND_SORTABLE_STRATEGY} from '../../../util/constants';
import {MenuNode} from '../../../domain/Menu';
import {getMenuKey} from '../../../services/MenuService';
import {useRef} from 'react';
import {createPortal} from 'react-dom';

interface MenuListProps {
  business: BusinessDto;
  menus: MenuDto[];
  active: MenuDto;
  onMenuSort: (menus: MenuDto[]) => void;
  onSectionSort: (menu: MenuDto, sections: MenuNode[]) => void;
  onSectionClick: (section: MenuNode, menu: MenuDto) => void;
}

export default function MenuList({business, menus, active, onMenuSort, onSectionSort, onSectionClick}: MenuListProps) {
  const history = useHistory();

  const emptySpan = useRef(null);

  const DND_SENSORS = useSensors(useSensor(PointerSensor, {
    onActivation({event}: { event: Event }) {
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }
    }
  }));

  function onMenuClick(active: MenuDto) {
    emptySpan.current.focus();
    setTimeout(() => {
      history.push(getMenusPath(business.id, active.id));
    }, 100);
  }

  function onNewMenu() {
    emptySpan.current.focus();
    setTimeout(() => {
      history.push(RoutesEnum.DashboardMenusNew);
    }, 200);
  }

  function onDragEnd(event: DragEndEvent) {
    const {active, over} = event;

    if (active.id !== over.id) {
      const oldIndex = menus.findIndex(it => it.id === active.id);
      const newIndex = menus.findIndex(it => it.id === over.id);

      onMenuSort(arrayMove(menus, oldIndex, newIndex));
    }
  }

  function handleEmptySpan(el: HTMLSpanElement) {
    emptySpan.current = el;
  }

  return (<ul className={styles.list}>
    <DndContext modifiers={DND_MODIFIERS} sensors={DND_SENSORS} collisionDetection={DND_COLLISION_DETECTION} onDragEnd={onDragEnd} autoScroll={false}>
      {createPortal(<DragOverlay> </DragOverlay>, document.body)}
          
      <SortableContext strategy={DND_SORTABLE_STRATEGY} items={menus.map(it => it.id)}>
        {menus.filter(it => !it.removed).map((it, idx) =>
          <MenuListEntrySortable
            key={getMenuKey(it)}
            item={it}
            showDragHandle={menus.length > 1}
            onMenuClick={onMenuClick}
            onSectionClick={(s) => onSectionClick(s, it)}
            onSectionSort={(sections) => onSectionSort(it, sections)}
            className={it.id === active.id ? styles.act : ''}
            active={it.id === active.id}
            opened={idx === 0}/>
        )}
      </SortableContext>
    </DndContext>
    <li className={`${styles.addMenuBtn} ${!active.id ? styles.act : ''}`}>
      <Button className={'btn-lg btn-sec-3'} onClick={onNewMenu}>+&nbsp;{'Add menu'}</Button>
    </li>
    <span ref={handleEmptySpan}></span>
  </ul>);
};
