import * as React from 'react';
import {Button, Input} from "antd";
import {useEffect, useState} from "react";
import {MenuItemModifier, MenuNode} from "../../../domain/Menu";
import {BusinessDto} from "../../../gen/client";
import {PlusOutlined, DeleteOutlined} from "@ant-design/icons/lib";
import styles from './MenuSection.module.scss';
import {DndContext, DragEndEvent, DragOverlay, PointerSensor, useSensor, useSensors} from '@dnd-kit/core';
import {arrayMove, SortableContext} from '@dnd-kit/sortable';
import {MenuItemSortable} from './MenuItemSortable';
import {DND_COLLISION_DETECTION, DND_MODIFIERS, DND_SORTABLE_STRATEGY} from '../../../util/constants';
import {MenuDataType, MenuItemModifierDetail} from '../../../util/types';
import {createPortal} from 'react-dom';
import DeleteMenuDataModal from "./DeleteMenuDataModal";

interface MenuSectionProps {
  section: MenuNode;
  business: BusinessDto;
  currency: string;
  modifiers: MenuItemModifierDetail[];
  reference: any;
  scrollToBottom: boolean;
  onSectionUpdate: (section: Partial<MenuNode>) => void;
  onModifierRemovedGlobal: (m: MenuItemModifier) => void;
  onItemUpdate: (sectionId: string, itemId: string, patch: Partial<MenuNode>) => void;
  onItemRemove: (sectionId: string, itemId: string) => void;
  onItemAdd: (sectionId: string) => void;
  onItemSort: (sectionId: string, nodes: MenuNode[]) => void;
  onSectionRemove: (id: string) => void;
  onCurrencyChange: (currency: string) => void;
  onItemImport: (section: MenuNode) => void;
  onItemClick: (itemId: string) => void;
  editItemIds?: string[];
  onCancel: () => void;
}

export default function MenuSection({
                                      section, business, modifiers, currency, reference, scrollToBottom, onSectionUpdate, onItemRemove, onItemAdd, onItemUpdate, onSectionRemove,
                                      onItemSort, onCurrencyChange, onModifierRemovedGlobal, onItemImport, onItemClick, editItemIds, onCancel
                                    }: MenuSectionProps) {
  const [isRemoveModal, setRemoveModal] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [sortable, setSortable] = useState(false);
  const DND_SENSORS = useSensors(useSensor(PointerSensor));

  useEffect(() => {
    if (!section || !section.nodes || !editItemIds) return;

    setSortable(true);
    section.nodes.map(n => n.id).forEach(id => {
      if (!!editItemIds?.find(it => it === id)) {
        setSortable(false);
      }
    });
  }, [editItemIds, section]);

  function onRemove(sectionId: string, itemId: string) {
    onItemRemove(sectionId, itemId);
  }

  function onDragEnd(event: DragEndEvent) {
    const {active, over} = event;

    if (active.id !== over.id) {
      const oldIndex = section.nodes.findIndex(it => it.id === active.id);
      const newIndex = section.nodes.findIndex(it => it.id === over.id);

      onItemSort(section.id, arrayMove(section.nodes, oldIndex, newIndex));
    }
    setDragging(false);
  }

  function onDragStart() {
    setDragging(true);
  }

  return (
    <div className={styles.section} ref={reference}>
      <header>
        <Input className={'nm'} size="large" defaultValue={section.name} autoFocus={!section.name && scrollToBottom} placeholder={'Section name'} onBlur={(ev) => onSectionUpdate({name: ev.target.value})}/>
        <Button type="link" className={styles.lk} onClick={() => setRemoveModal(true)}><DeleteOutlined/></Button>
      </header>
      <section>
        <Input.TextArea defaultValue={section.description} placeholder={'Section Note'} onBlur={ev => onSectionUpdate({description: ev.target.value})}/>
        <p/>
      </section>

      <div className={'items'}>
        <DndContext modifiers={DND_MODIFIERS} sensors={DND_SENSORS} collisionDetection={DND_COLLISION_DETECTION} onDragEnd={onDragEnd} onDragStart={onDragStart}>
          {createPortal(<DragOverlay> </DragOverlay>, document.body)}

          <SortableContext strategy={DND_SORTABLE_STRATEGY} items={section.nodes.map(it => it.id)}>
            {section.nodes.map((node, idx) => <MenuItemSortable
              key={node.id}
              showDragHandle={section.nodes.length > 1}
              sortable={sortable}
              business={business}
              item={node}
              modifiers={modifiers}
              onItemUpdate={onItemUpdate}
              currency={currency}
              sectionId={section.id}
              dragging={dragging}
              scrollToBottom={scrollToBottom && idx === section.nodes.length - 1}
              onItemRemove={onRemove}
              onCurrencyChange={onCurrencyChange}
              onModifierRemovedGlobal={onModifierRemovedGlobal}
              editMode={!!editItemIds?.find(it => it === node.id)}
              onCancel={onCancel}
              onClick={() => onItemClick(node.id)}/>)}
          </SortableContext>
        </DndContext>
      </div>

      <div className={styles.add}>
        <Button type={'default'} className={styles.defaultButton + ' btn-med btn-sec-3'} onClick={() => onItemAdd(section.id)} icon={<PlusOutlined/>}>New Item</Button>
        <Button type={'default'} className={styles.defaultButton + ' btn-med btn-sec-2' } onClick={() => onItemImport(section)} icon={<PlusOutlined/>}>Saved Items</Button>
      </div>

      <DeleteMenuDataModal visible={isRemoveModal} onConfirm={() => onSectionRemove(section.id)} onCancel={() => setRemoveModal(false)} type={MenuDataType.SECTION} />
    </div>
  );
};
