import {MenuItemModifierDetail} from '../util/types';
import {MenuItemData, MenuItemType, MenuNode, MenuNodeType} from "../domain/Menu";
import {uuid} from "../util/Utils";
import {ImageDtoSourceEnum, MenuDto, TimberItemDto} from '../gen/client';

export function getSectionNode(item: MenuItemData) {
  const node = new MenuNode(item.id);
  node.name = item.name;
  node.description = item.description;
  return node;
}

export function getItemNode(item: MenuItemData) {
  const node = new MenuNode(item.id, MenuNodeType.ITEM);
  node.name = item.name;
  node.description = item.description;
  node.preview = item.preview;
  node.prices = [item.price || '0'];
  return node;
}

export function getMenuNodes(data: string): MenuNode[] {
  const items = JSON.parse(data || '[]') as MenuItemData[];
  const nodes = [] as MenuNode[];

  if (items.length > 0) {
    let section = items[0].type === MenuItemType.SECTION ? getSectionNode(items[0]) : new MenuNode(uuid());
    const start = items[0].type === MenuItemType.SECTION ? 1 : 0;

    for (let idx = start; idx < items.length; idx++) {
      const item = items[idx];
      if (item.type === MenuItemType.SECTION) {
        nodes.push(section);
        section = getSectionNode(item);
      } else {
        section.nodes.push(getItemNode(item));
      }
    }
    nodes.push(section);
  }

  return nodes;
}

export function countEmptyItems(nodes: MenuNode[]): number {
  return nodes
    .filter(it => it.type === MenuNodeType.SECTION)
    .map(section => section.nodes.filter(item => !item.name || !item.prices[0]).length)
    .reduce((a, b) => a + b, 0);
}

export function cleanEmptyItems(nodes: MenuNode[]): MenuNode[] {
  nodes
    .filter(it => it.type === MenuNodeType.SECTION)
    .forEach(section => section.nodes = section.nodes.filter(item => !!item.name || !!item.prices[0]))
  return [...nodes];
}

export function cleanItemsWithInvalidPrice(nodes: MenuNode[]): MenuNode[] {
  nodes
    .filter(it => it.type === MenuNodeType.SECTION)
    .forEach(section => section.nodes = section.nodes.filter(item => typeof item.prices[0] === 'string' && isNaN(parseFloat(item.prices[0]))))
  return [...nodes];
}

export function hasItems(nodes: MenuNode[]): boolean {
  return nodes
    .filter(it => it.type === MenuNodeType.SECTION)
    .filter(it => it.nodes.length > 0)
    .length > 0
}

export function hasValidMenu(menus: MenuDto[]): boolean {
  return menus.filter(it => !it.removed).length > 0 && menus.filter(it => hasItems(JSON.parse(it.nodes || '[]'))).length > 0;
}

export function timberItemToMenuItem(items: TimberItemDto[]): MenuNode[] {
  return items.map(it => {
    const item = new MenuNode(uuid(), MenuNodeType.ITEM);
    item.name = it.name;
    item.description = it.description;
    item.prices = [];
    item.sizes = [];
    it.prices.forEach(p => {
      item.prices.push(p.price);
      item.sizes.push(p.size);
    });
    item.extras = it.extras;
    return item;
  });
}

export function extractPrice(price: string): number {
  return parseFloat(price.replace('$', ''))
}

export function getMenuModifiers(nodes: MenuNode[]): MenuItemModifierDetail[] {
  const modifiers = [] as MenuItemModifierDetail[];

  nodes.forEach(node => {
    node.nodes.forEach(item => {
      if (item && item.modifiers) {
        item.modifiers.forEach(m => {
          const existing = modifiers.find(it => it.modifier.name === m.name);

          if (!!existing) {
            existing.usedIn.push(item);
          } else {
            modifiers.push({
              modifier: m,
              usedIn: [item]
            })
          }
        });
      }
    });
  });

  return modifiers;
}

export function getMenuKey(menu: MenuDto) {
  let sectionNodeIds = '';
  try {
    sectionNodeIds = (JSON.parse(menu.nodes || '[]') as MenuNode[]).map(it => it.id).join();
  } catch (e) {
    console.error(e);
  }
  return `${menu.id}${sectionNodeIds}`
}

export interface TimberMenuObject {
  id: string;
  visible: boolean;
  selected: boolean;
  items: TimberItemDto[];
}

const DefaultMenuObject = {id: '', visible: true, selected: false, items: []} as TimberMenuObject;

export function groupMoItems(items: TimberItemDto[]): TimberMenuObject[] {
  const objects = [] as TimberMenuObject[];
  let object = {...DefaultMenuObject, items: []} as TimberMenuObject;

  console.log(items);
  items.forEach(item => {
    if (!!object.id && object.id !== item.objectId) {
      objects.push(object);
      object = {...DefaultMenuObject, id: item.objectId, items: []};
    }
    object.id = item.objectId;
    const descriptionObj = document.createElement('div');
    descriptionObj.innerHTML = item.description.trim();
    item.description = descriptionObj.innerText;
    const nameObj = document.createElement('div');
    nameObj.innerHTML = item.name.trim();
    item.name = item.bin ? `${item.bin} ${nameObj.innerText}` : nameObj.innerText;
    object.items.push(item);
  });

  if (!!object.id) {
    objects.push(object);
  }

  console.log(objects);
  return objects;
}

export function fixVersionedNodes(menu: MenuDto) {
  const nodes = JSON.parse(menu.nodes || '[]');

  nodes.forEach((section: any) => {
    section.nodes.forEach((item: any) => {
      if (!item.hasOwnProperty('available')) {
        item.available = true;
      }
    })
  });

  return nodes;
}

export function isOOImage(source?: ImageDtoSourceEnum) {
  if (!source) return true;
  return source === ImageDtoSourceEnum.Ordering;
}
