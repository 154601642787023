import * as React from 'react';
import './ImportItemModal.scss';
import {Button, Checkbox, Input} from "antd";
import {useEffect} from "react";
import MenuApi from "../../../api/MenuApi";
import {AxiosError} from "axios";
import {handleServerError} from "../../../util/ErrorHandler";
import {useState} from "react";
import {TimberItemDto, TimberMenuDto} from "../../../gen/client";
import {SearchOutlined} from "@ant-design/icons/lib";
import {groupMoItems, TimberMenuObject} from "../../../services/MenuService";
import Loader from "../../misc/Loader";

export interface ImportItemDataProps {
  active: TimberMenuDto;
  onClose: () => void;
  goBack: () => void;
  onSuccess: (items: TimberItemDto[]) => void;
}

export default function ImportItemData({active, goBack, onClose, onSuccess}: ImportItemDataProps) {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [objects, setObjects] = useState([] as TimberMenuObject[]);

  useEffect(() => {
    if (!active) return;
    setSearch('');
    MenuApi.getDesignMenuObjects(active.id).then(resp => {
      resp.data.forEach(it => it.visible = true);
      setObjects(groupMoItems(resp.data));
      setLoading(false);
    }).catch((err: AxiosError) => {
      setLoading(false);
      handleServerError(err);
    });
  }, [active]);

  function doSearch(value: string) {
    setSearch(value);
    objects.forEach(object => {
      let hidden = 0;
      object.items.forEach(it => {
        it.visible = it.name.toLowerCase().indexOf(value) > -1;
        if (!it.visible) hidden += 1;
      });
      object.visible = hidden < object.items.length;
    })
    setObjects([...objects]);
  }

  function toggle(item: TimberItemDto) {
    item.selected = !item.selected;
    setObjects([...objects]);
  }

  function toggleObject(object: TimberMenuObject) {
    object.selected = !object.selected;
    object.items.forEach(it => it.selected = object.selected);
    setObjects([...objects]);
  }

  function performImport() {
    let selected = [] as TimberItemDto[];
    objects.forEach(object => {
      object.items.filter(it => it.selected && it.visible).forEach(it => selected.push(it))
    });
    onSuccess(selected);
  }

  function getItemPrice(prices: { [key: string]: string }[]) {
    if (!prices || !prices.length) return '';
    return `${prices[0].size || ''} ${prices[0].price || ''}`.trim();
  }

  return (
    <>
      <header>
        <h6>Select items to import</h6>
        <h2>{active.name}</h2>
        <div className={'back'}>
          <Button type={'link'} onClick={goBack}>Change Menu</Button>
        </div>
        <Input value={search} prefix={<SearchOutlined/>} placeholder={'Search'} onChange={ev => doSearch((ev.target.value || '').toLowerCase())}/>
      </header>

      {loading && <Loader />}

      {!loading && <div className={'scroller'}>
        <ul className={'objects'}>
          {objects.filter(it => it.visible).map(object =>
            <li key={object.id}>
              {object.items.filter(it => it.visible).length > 1 && <Checkbox checked={object.selected} onClick={() => toggleObject(object)}/>}

              <ul className={'items'}>
                {object.items.filter(it => it.visible).map((item, idx) =>
                  <li key={`${item.name}-${idx}`} className={item.selected ? 'act' : ''} onClick={() => toggle(item)}>
                    <label>{item.name}</label>
                    <label className={'price'}>{getItemPrice(item.prices)}</label>
                  </li>)}
              </ul>
            </li>
          )}
        </ul>
      </div>}

      <footer>
        <Button className={"btn-med btn-cancel"} onClick={onClose}>Cancel</Button>
        <Button className={"btn-med"} type={'primary'} disabled={!active} onClick={performImport}>Import Items</Button>
      </footer>
    </>
  );
};
