import {env} from "./env/env";
import {isProd, isStage} from "./util/Utils";

export const RoutesEnum = {
  Root: env.contextPath + '/',
  App: env.contextPath + '/app',
  Login: env.contextPath + '/login',
  Start: env.contextPath + '/start',
  About: env.contextPath + '/about',
  PassForgot: env.contextPath + '/pass-forgot',
  Order: env.contextPath + '/places',
  LightOrder: env.contextPath,
  Dashboard: env.contextPath + '/app',
  DashboardBusiness: env.contextPath + '/app/biz',
  DashboardOrders: env.contextPath + '/app/orders',
  DashboardMenus: env.contextPath + '/app/biz/:bizId/menus',
  DashboardMenusNew: env.contextPath + '/app/biz/:bizId/menus/new',
  DashboardDesign: env.contextPath + '/app/designs',
  DashboardSettings: env.contextPath + '/app/settings',
  DashboardSettingsHours: env.contextPath + '/app/settings/hours',
  DashboardSettingsStatus: env.contextPath + '/app/settings/status',
  DashboardSettingsSEO: env.contextPath + '/app/settings/seo',
  DashboardSettingsSavedVersions: env.contextPath + '/app/settings/saved-versions',
  DashboardSettingsSite: env.contextPath + '/app/settings/site-settings',
  DashboardSettingsAnnouncementBanner: env.contextPath + '/app/settings/announcements',
  DashboardSettingsPayments: env.contextPath + '/app/settings/payments',
  DashboardSettingsOrdering: env.contextPath + '/app/settings/ordering',
  DashboardCustomers: env.contextPath + '/app/customers',
  DashboardWebsite: env.contextPath + '/app/website',
  DashboardWebsitePage: env.contextPath + '/app/website/pages',
  DashboardWebsitePageNew: env.contextPath + '/app/website/pages/new',
  Onboarding: env.contextPath + '/onboard',
  Admin: env.contextPath + '/adm',
  Settings: env.contextPath + '/settings',
  SettingsGeneral: env.contextPath + '/settings/general',
  SettingsSubs: env.contextPath + '/settings/subs',
  SettingsSecurity: env.contextPath + '/settings/security',

  MenusPath: '/menus',

  MyStuff: '/home',
  MyStuffStaging: 'https://staging.mhmfun.com/menu/home',
  MyStuffProd: 'https://www.musthavemenus.com/home',

  StagingDomain: 'https://staging.mhmfun.com',
  ProdDomain: 'https://www.musthavemenus.com'
}

export const getMenusPath = (bizId: string, menuId: string): string => RoutesEnum.DashboardMenus.replace(':bizId', bizId) + (menuId ? `/${menuId}` : '');
export const getBusinessPath = (bizId: string) => `${RoutesEnum.DashboardBusiness}${bizId ? `/${bizId}` : ''}`;

export const isAuthPath = (pathname: string) => pathname.indexOf(RoutesEnum.Login) > -1 || pathname.indexOf(RoutesEnum.Start) > -1;
export const isPassForgot = (pathname: string) => pathname.indexOf(RoutesEnum.PassForgot) > -1;
export const isDashboardPath = (pathname: string) => pathname.indexOf(RoutesEnum.Dashboard) > -1;
export const isOrderPath = (pathname: string) => pathname.indexOf(RoutesEnum.Order) > -1;
export const isOnboardPath = (pathname: string) => pathname.indexOf(RoutesEnum.Onboarding) > -1;
export const isAdmPath = (pathname: string) => pathname.indexOf(RoutesEnum.Admin) > -1;
export const isPresentationView = (pathname: string) => pathname.indexOf(RoutesEnum.About) > -1;
export const isMenusPath = (pathname: string) => pathname.indexOf('/menus') > -1;
export const isNewMenusPath = (pathname: string) => pathname.indexOf(RoutesEnum.DashboardMenusNew) > -1;

export function hasHeader(pathname: string) {
  return !isAuthPath(pathname) && !isDashboardPath(pathname) && !isOrderPath(pathname) && !isOnboardPath(pathname) && !isAdmPath(pathname) && !isPassForgot(pathname);
}

export function hasFooter(pathname: string) {
  return !isAuthPath(pathname) && !isDashboardPath(pathname) && !isOrderPath(pathname) && !isAdmPath(pathname) && !isPassForgot(pathname);
}

export function isPublicPage(pathname: string) {
  return pathname.indexOf('/app') < 0;
}

export function getSettingsPath(key: string): string {
  if (key === '2') return RoutesEnum.SettingsSubs;
  if (key === '3') return RoutesEnum.SettingsSecurity;
  return RoutesEnum.SettingsGeneral;
}

export function getRouteWebsiteByPath(path: string) {
  return `${RoutesEnum.DashboardWebsite}/${path}`;
}

export function getRouteWebsitePageById(id: string) {
  return `${RoutesEnum.DashboardWebsitePage}/${id}`;
}

export function getRouteOrder(url: string, menuFriendlyId: string) {
  return `${RoutesEnum.Order}/${url}?mid=${menuFriendlyId || ''}`;
}

export function getRouteOrderCheckout(url: string, orderId: string, menuFriendlyId: string) {
  return `${RoutesEnum.Order}/${url}/checkout?id=${orderId}&mid=${menuFriendlyId}`;
}

export function getRouteOrderSuccess(url: string, friendlyOrderId: string) {
  return `${RoutesEnum.Order}/${url}/success?fid=${friendlyOrderId}`;
}

export function getRouteOrderReview(url: string, orderId: string, menuFriendlyId: string) {
  return `${RoutesEnum.Order}/${url}?id=${orderId || ''}&mid=${menuFriendlyId || ''}`;
}

export function redirectToLogin() {
  if (env.basePath.indexOf('localhost') < 0 && !isPublicPage(window.location.href)) {
    if (isStage()) {
      window.location.href = 'https://staging.mhmfun.com';
    } else if (isProd()) {
      window.location.href = 'https://www.musthavemenus.com';
    } else {
      window.location.href = window.location.origin;
    }
  }
}
