import * as React from 'react';
import './StaticMenuLine.scss';
import {getImagePath} from "../../services/ImageService";
import {MenuNode} from "../../domain/Menu";
import Price from "../Price";
import {BusinessDto} from '../../gen/client';
import {menuTextColor} from "../../services/StylesService";
import {isOOImage} from "../../services/MenuService";

interface StaticMenuLineProps {
  isPreview: boolean;
  node: MenuNode;
  business: BusinessDto;
  onClick: (item: MenuNode) => void;
}

/*
  This is should be used for Online Menus without Online Ordering.
  For the Online Ordering version always use the <MenuLine /> component.
 */
export default function StaticMenuLine({isPreview, node, business, onClick}: StaticMenuLineProps) {
  return (
    <div className={`static-menu-line ${!node.preview ? 'no-prev one-column ' : ''} ${!isPreview ? 'cursor-not-preview' : ''} `}>
      <div id={'staticMenuLineId'} className={'data'} style={menuTextColor(business)} onClick={() => onClick(node)}>
        <h3 className="content two-lines" style={menuTextColor(business)}>{node.name}</h3>
        {node.description || node.prices[0] || node.extras ?
          <p>
            <span className="content four-lines">{node.description}</span>
            <span className="content one-line">
              {node.prices.filter(it => !!it).map((price, idx) => (
                <span key={idx}>
                  {node.sizes && node.sizes[idx] && node.prices.length > 1 && (<span>{node.sizes[idx]}&nbsp;</span>) }
                  <Price business={business} currency={business.config?.currency} value={price}/>
                  {idx < node.prices.length - 1 && ' | '}
                </span>))
              }
            </span>
            <span className="content two-lines">{node.extras}</span>
          </p> : null}
      </div>
      {node.preview && <div className="preview" style={{backgroundImage: `url(${getImagePath(node.preview, isOOImage(node.previewSource))})`}} onClick={() => onClick(node)}/>}
    </div>
  );
}
