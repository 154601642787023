import * as React from "react";
import { Button } from "antd";
import Price from "../../Price";
import styles from "./ItemView.module.scss";
import ItemPreview from "./ItemPreview";
import { DeleteOutlined } from "@ant-design/icons";
import {BusinessDto, ImageDtoSourceEnum} from "../../../gen/client";
import {MenuDataType} from "../../../util/types";
import DeleteMenuDataModal from "./DeleteMenuDataModal";
import {useState} from "react";

interface ItemViewProps {
  available: boolean;
  currency: string;
  description: string;
  extras: string | undefined;
  id: string;
  name: string;
  preview: string;
  previewSource: ImageDtoSourceEnum,
  prices: string[];
  sectionId: string;
  sizes: string[];
  business: BusinessDto;

  onItemRemove: (sectionId: string, itemId: string) => void;
  onClick: () => void;
}

export default function ItemView({
  available,
  currency,
  description,
  extras,
  id,
  name,
  preview,
  previewSource,
  prices,
  sectionId,
  sizes,
  business,
  onItemRemove,
  onClick,
}: ItemViewProps) {

  const [isRemoveModal, setRemoveModal] = useState(false);

  function onRemove(e: React.MouseEvent) {
    e.stopPropagation();
    onItemRemove(sectionId, id);
  }

  return (
    <div className={`${styles.view} ${!available ? 'unavailable' : ''}`} onClick={onClick}>
      <section>
        <div>
          <h3 className={`${styles.content} ${styles.lineClamp}`}>{name}</h3>
          {business.orderingEnabled ?
            <ul>
              {prices.map((price, idx) => (
                <li key={idx}>
                  <span>{sizes && sizes[idx] ? `${sizes[idx]} `: null}</span>
                  <Price business={business} currency={currency} value={price} />
                </li>
              ))}
            </ul>:
            <ul className={`${styles.ulNotEnabled} ${styles.content} ${styles.oneLineClamp}`}>
              {prices.map((price, idx) => (
                <li key={idx}>
                  {sizes && sizes[idx] && prices.length > 1 && <><span>{sizes[idx]}</span>&nbsp;</>}
                  <Price business={business} currency={currency} value={price} />&nbsp;{idx < prices.length-1? <>|&nbsp;</> : null}
                </li>
              ))}
            </ul>
          }
        </div>
      </section>
      <ItemPreview preview={preview} previewSource={previewSource} />
      <div className={styles.buttonsContainer}>
        <Button type={"link"} onClick={(e) => {e.stopPropagation(); setRemoveModal(true)}}>
          <DeleteOutlined />
        </Button>
      </div>

      <DeleteMenuDataModal visible={isRemoveModal} onConfirm={onRemove} onCancel={(e) => {e.stopPropagation(); setRemoveModal(false)}} type={MenuDataType.ITEM} />
    </div>
  );
}
